@import "../_palette";
@import "./colors.scss";

.messageBox {
    width: 100%;
    background-color: $white;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid $midnight-20;
    border-left-width: 5px;
    color: $default;
    display: flex;
    align-items: center;
    float: left;
    font-size: 0.85rem;

    .alertText {
        padding-left: 0.7rem;
        align-self: center;
    }

    &.error {
        border-left-color: $sunset;
    }

    &.info {
        border-left-color: $info;
    }

    &.warning {
        border-left-color: $warning;
    }

    &.success {
        border-left-color: $moss;
    }

    > svg {
        height: 24px;
        width: 24px;
        overflow: initial;
        align-self: flex-start;
    }
}

.small {
    padding: 6px 16px;
}

.default {
    padding: 16px 16px;
}
