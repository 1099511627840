@import "../_palette";
@import "./colors.scss";

.errorRenderBox {
    width: 200px;
}

.errorRenderBox .image {
    width: auto;
}

.errorRenderBox .legend {
    text-align: center;
}
