@import "colors";
@import "vars";

.label {
    color: $dusk;
    font-size: small;
}

.custom_date_picker {
    display: flex;
    flex-direction: row;
    border: 1px solid $midnight-20;
    border-radius: 5px;
    font-family: $body-font-family;
    font-size: 12px;
    color: $dusk;

    &:focus-within {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
    }

    & &_text_field {
        width: 100%;
        height: inherit;
    }

    & &_input {
        text-align: center;
        font-size: 12px;
        color: $dusk;
        height: inherit;
    }

    &_decoration {
        display: flex;
        justify-content: center;
        color: $dusk-lighter;
        font-weight: bold;
        letter-spacing: 0.02857em;
        background-color: $midnight-10;
        font-family: $body-font-family;
        align-items: center;
        text-align: center;
        border-right: 1px solid $midnight-20;
        padding-left: 8px;
        padding-right: 8px;
        min-width: max-content;
        width: 40%;
    }

    & > div {
        flex-grow: 1;
        min-width: fit-content;
    }
}
