$white: #ffffff;
$black: #000000;
$gray-one: #2c3439;
$gray-two: #4c5357;
$gray-three: #99adb6;
$gray-four: #b2c2c8;
$gray-five: #ccd6db;
$gray-six: #e5ebed;
$gray-seven: #fbfbfc;

$dusk: #2c3439;
$dusk-light: #4c5357;
$dusk-lighter: #707579;
$dusk-lightest: #969a9c;
$dusk-thirty: #bfc2c3;
$dusk-twenty: #d5d6d7;
$dusk-ten: #e9eaeb;
$dusk-seven: #f0f1f1;
$dusk-three: #f8f9f9;
$dusk-one: #fcfdfd;

$gray: $dusk;
$gray-light: $dusk-light;
$gray-lighter: $dusk-lighter;
$gray-lightest: $dusk-lightest;

$vacasa-yorange: #ffad02;
$owner-yellow: #ffd00a;
$guest-yellow: #ffe700;

$midnight: #003349;
$midnight-100: #003349;
$midnight-90: #1a475b;
$midnight-80: #335c6d;
$midnight-70: #4d7080;
$midnight-60: #668592;
$midnight-50: #8099a4;
$midnight-40: #99adb6;
$midnight-30: #b2c2c8;
$midnight-20: #ccd6db;
$midnight-10: #e5ebed;
$midnight-4: #f5f7f8;
$midnight-2: #fbfbfc;

$cabin: #783d24;
$sunset: #d62e4f;
$jewel: #631c69;
$coast: #004273;
$forest: #0f633d;
$clay: #d16333;
$coral: #ff9470;
$wine: #a3286d;
$surf: #00899e;
$moss: #6ba342;
$cafe: #ddad49;
$sand: #ffd19e;
$lavander: #d18ab2;
$caribbean: #89d8c6;
$meadow: #b2d872;

$lake: #0078ab;
$lake-10: #e5f1f6;
$lake-70: #4ca0c4;

$shadow-color: rgba(0, 0, 0, 0.08);
