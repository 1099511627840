@import "../_palette";

$primary: $owner-yellow;
$primary-light: mix(white, $primary, 30%);
$primary-dark: mix(black, $primary, 10%);

$secondary: $lake;
$secondary-light: mix(white, $secondary, 30%);
$secondary-dark: mix(black, $secondary, 10%);

$default: $dusk;
$default-light: mix(white, $default, 30%);
$default-dark: mix(black, $default, 10%);

$info: $midnight;
$info-light: mix(white, $info, 30%);
$info-dark: mix(black, $info, 10%);

$success: #00a433;
$success-light: mix(white, $success, 30%);
$success-dark: mix(black, $success, 10%);

$danger: #bd0024;
$danger-light: mix(white, $danger, 30%);
$danger-dark: mix(black, $danger, 10%);

$warning: $cafe;
$warning-light: mix(white, $warning, 30%);
$warning-dark: mix(black, $warning, 10%);
