@import "./vars.scss";

.nights {
    display: flex;
    flex: 0 0 25%;
    min-height: 100%;
    margin-bottom: 5px;
    border-right: 1px solid rgba(2, 2, 2, 0.125);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_inner {
        &_day {
            color: #003349;
            margin: 0px;
            font-weight: lighter;
            text-align: center;
            font-size: 35px;
        }
        &_title {
            color: #2c3439;
            font-size: x-small;
            font-weight: bolder;
            margin: 0;
        }
    }
}

.divider {
    flex: 0 0 8.333333%;
    &_arrow {
        & svg {
            width: 100px;
        }
    }
}

.inner {
    display: flex;
    position: relative;
    div[class^="staypaneldate_container"] {
        flex: 0 0 33.33333%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.container {
    padding: 8px 0px;
    border: 1px solid rgba(2, 2, 2, 0.125);
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(44, 52, 57, 0.15);
    background: white;

    svg.active {
        stroke: #0078ae;
    }
}

// Mobile Styles
@media #{$mobile} {
    .nights {
        display: none;
    }
    .inner {
        div[class^="staypaneldate_container"] {
            flex: 0 0 41.333333%;
            max-width: 41.333333%;
            h5 {
                font-size: large;
            }
        }
    }
    .divider {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}
