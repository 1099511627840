@import "../_palette";
@import "./colors.scss";

.badge {
    font-family: "Nunito Sans", sans-serif;
    padding: 0.35em 0.65em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;

    &.pill {
        border-radius: 10rem;
    }
    &.outline {
        color: #000 !important;
        background: transparent !important;
        &.error {
            border: 1px solid $sunset;
        }
        &.success {
            border: 1px solid $moss;
        }
        &.warning {
            border: 1px solid $warning;
        }
        &.info {
            border: 1px solid $info;
        }
        &.default {
            border: 1px solid #e5ebed;
        }
    }
    &.disabled {
        opacity: 0.5;
    }
    &.success {
        background-color: $moss;
        color: #fff;
    }
    &.error {
        background-color: $sunset;
        color: #fff;
    }
    &.warning {
        background-color: $warning;
        color: #fff;
    }
    &.info {
        background-color: $info;
        color: #fff;
    }
    &.default {
        background-color: #ffffff;
        color: #2c3439;
    }
}

.closeButton {
    margin-left: 11px;
    cursor: pointer;
    font-size: 20px;
    &.default {
        color: #2c3439;
    }
}
