@import "../palette";

.container {
    display: flex;
    flex-direction: row;
    padding: 0px 8px;
}

.right {
    margin-left: auto;
}

.inner {
    display: flex;
    flex-direction: row;
    font-size: x-small;
    background-color: $gray-six;
    padding: 8px;
    border-radius: 5px;
    &__link {
        color: $lake;
        cursor: pointer;
    }
    svg {
        cursor: pointer;
        margin-left: 16px;
        stroke: #969a9c;
        flex-grow: 2;
        min-height: 15px;
        min-width: 15px;
        max-height: 15px;
        max-width: 15px;
        margin-right: 8px;
        stroke-width: 5;
    }
}
