@import "../_palette";
@import "./colors.scss";

.sticky {
    display: inline-block;
    padding: 0.3rem 0.3rem 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.small {
    font-size: 0.8rem;
}

.smaller {
    font-size: 0.6rem;
}

.error {
    color: $sunset;
}

.info {
    color: $info;
}

.warning {
    color: $warning;
}

.success {
    color: $moss;
}

.default {
    color: $dusk;
}
