@import "colors";

.label {
    color: $default;
    font-size: small;
    min-width: max-content;
}

.custom_select {
    & &_text_field {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        height: inherit;
    }

    & &_input {
        text-align: center;
        font-size: 12px;
        color: $dusk;
        height: inherit;
    }

    & &_input_focused {
        border: 1px solid $midnight-30;
        outline: 0;
    }
}
