@import "colors";
@import "vars";

.label {
    color: $dusk;
    font-size: small;
}

.custom_input {
    display: flex;
    flex-direction: row;
    border: 1px solid $midnight-20;
    border-radius: 5px;
    font-size: 12px;
    color: $dusk;
    height: inherit;

    &:focus-within {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
    }

    & &_text_field {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    & &_input {
        text-align: center;
        font-size: 12px;
        color: $dusk;
        height: inherit;
    }

    &_decoration {
        display: flex;
        justify-content: center;
        color: $dusk-lighter;
        font-weight: bold;
        background-color: $midnight-10;
        align-items: center;
        text-align: center;
        border-right: 1px solid $midnight-20;
        font-family: $body-font-family;
        padding-left: 8px;
        padding-right: 8px;
        min-width: max-content;
        width: 40%;
        letter-spacing: 0.02857em;
    }

    & > div {
        flex-grow: 1;
    }
}
