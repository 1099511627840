@import "./vars.scss";
@import "../palette";

.icon {
    vertical-align: middle;
    max-height: 49px;
    max-width: 49px;
}

.message {
    color: $white;
    text-decoration: none;
    font-size: 16px;
    padding: 8px 8px 8px 8px;
    a {
        text-decoration: underline;
        color: $white;
        font-size: 16px;
        padding: 8px 8px 8px 8px;
    }
}

.container {
    font-family: $body-font-family;
    position: relative;
    display: block;
    background: linear-gradient(-90deg, rgb(51, 92, 109) 0%, rgb(0, 51, 73) 100%);
    text-align: center;
    padding: 7px 0 0 0;
    line-height: 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    height: 56px;
    right: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .message {
        font-size: 12px;
        padding: 0;
        a {
            text-decoration: underline;
            font-size: 12px;
            padding: 0;
            margin-left: 5px;
        }
    }
}
