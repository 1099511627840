@import "./vars.scss";
@import "./colors.scss";

@mixin btn($bg, $color, $border, $hover, $active: $hover) {
    box-shadow: inset 0 1px 4px rgba($info, 0), 0 0 0 3px rgba($default, 0);
    padding: 8px 24px;
    border-color: $border;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    min-width: fit-content;
    cursor: pointer;
    font-family: $body-font-family;
    font-size: $body-font-size;
    color: $color;
    background-color: $bg;

    svg {
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $color;
        }
    }

    &:hover {
        background-color: $hover;
        color: $color;
        border-color: $border;
        @if ($border == $bg) {
            border-color: $hover;
        }
    }
    &:disabled {
        color: $color;
        background-color: $bg;
        border-color: $border;
        opacity: 0.4;
        pointer-events: none;
    }
    &:focus,
    &:not(:disabled):not(.disabled):active {
        color: $color;
        border-color: $border;
        background-color: $bg;
        outline: none;
        box-shadow: none;
    }
}

.default {
    @include btn($white, $default, transparent, transparent);
}
.primary {
    @include btn($primary, $black, $primary, $primary-light);
}
.secondary {
    @include btn($secondary, $white, transparent, $secondary-light);
}
.info {
    @include btn($white, $secondary, $secondary, transparent);
}
.danger {
    @include btn($white, $danger, $danger, transparent);
}

.small {
    font-size: 14px !important;
}

.utility,
.text {
    @include btn($white, $gray-one, $white, $white);
    opacity: 50%;
    pointer-events: none;
}
