@import "colors";

.default {
    & &_indicator {
        background: $midnight-50;
    }

    & &_tab_header {
        text-transform: capitalize;
    }

    & &_selected {
        color: $midnight;
        font-weight: bold;
    }

    & &_tab_body {
        border-top: 1px solid $midnight-20;
        padding-top: 14px;
        height: 100%;
    }
}

.filled {
    & &_indicator {
        display: none;
    }

    & &_tab_header {
        text-transform: capitalize;
        border-radius: 5px 5px 0 0;
        margin-right: 1px;
        height: 40px;
    }

    & &_selected {
        background: $midnight-80;
        color: $white;
        font-weight: bold;
    }

    & &_not-selected {
        background: $midnight-10;
        color: $dusk-lighter;
    }

    & &_tab_body {
        border: 1px solid $midnight-20;
        border-bottom-left-radius: 5px 5px;
        border-bottom-right-radius: 5px 5px;
        padding-top: 14px;
        min-height: 100%;
    }
}
