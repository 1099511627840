.modalWindow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s;
    z-index: 999999999999;
    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3em 2em 2em 2em;
        background: white;
        word-break: break-all;

        &.large {
            width: 800px;
        }
        &.medium {
            width: 600px;
        }
        &.small {
            width: 450px;
        }
    }
}

.modalClose {
    cursor: pointer;
    color: #aaa;
    font-size: 1rem;
    position: absolute;
    right: 4%;
    text-align: center;
    top: 5%;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modalWindow {
    & > div {
        border-radius: 0.5rem;
    }
}

.modalWindow div:not(:last-of-type) {
    margin-bottom: 15px;
}
