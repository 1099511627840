@import "../_palette";
@import "./colors.scss";

.formControl {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0.8;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    margin-top: 1em;
}

.input {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 2px solid $midnight-40;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    outline: none;
    &::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        transform: scale(0);
        box-shadow: inset 1em 1em $lake;
        transition: 120ms transform ease-in-out;
    }
    &:checked::before {
        transform: scale(1);
    }

    &:checked {
        border: 2px solid $lake;
    }
}

.inputDisabled {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 2px solid $gray-six;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    outline: none;
    &::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $gray-six;
    }
    &:checked::before {
        transform: scale(1);
    }
}
