@import "./vars.scss";
@import "../palette";

.container {
    font-family: $body-font-family;
    display: inline-block;
    padding: 0px 15px;
    svg {
        width: 100%;
        height: 100%;
    }
    h5 {
        margin: 0px;
        font-size: 25px;
    }
}

.inner {
    text-align: left;
    margin-top: 5px;
}

.title {
    text-transform: uppercase;
    color: $dusk-light;
    font-weight: bolder;
    font-size: smaller;
    letter-spacing: 1px;
}

.footer {
    display: flex;
    &_icon {
        cursor: pointer;
        margin-left: 16px;
        max-width: 18px;
        stroke: #969a9c;
    }
    &_arrow {
        position: absolute;
        width: 1rem;
        height: 0.5rem;
        margin: 0;
        bottom: 1px;
        &:after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            top: 1px;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            border-bottom-color: $gray-six;
        }
    }
}

// Mobile Styles
@media (max-width: 767px) {
    .footer_time {
        font-size: smaller;
    }
}
