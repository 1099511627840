@import "colors";

.box {
    display: block;
    margin-top: 16px;

    &_title {
        color: $gray-one;
        font-size: larger;
        font-weight: bolder;
        margin-bottom: 8px;
    }

    &_text {
        text-align: left;
        line-height: 2;
    }

    &_toggle {
        margin-left: 2px;
        color: $lake;
        cursor: pointer;
    }
}
