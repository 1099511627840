@import "./colors.scss";

.infobox {
    display: flex;
    flex-direction: column;
    background-color: $midnight-2;
    width: 240px;
    height: 190px;
    border: 1px solid $midnight-20;
    border-radius: 4px 4px;

    .header {
        padding: 8px 16px;
        border-bottom: 1px solid $dusk-thirty;

        & .label {
            float: left;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            color: $dusk-light;
        }

        & .icon {
            float: right;
            > svg {
                transform: rotate(180deg);
                stroke: none;
                fill: none;

                > path {
                    stroke: none;
                    fill: $dusk-lightest;
                }
            }
        }
    }

    .content {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        &.vertical {
            flex-direction: column-reverse;

            & h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            span {
                font-size: 16px;
                font-family: Wigrum;
                font-weight: bold;
            }
            > div {
                flex-direction: column-reverse;
                span {
                    font-family: Nunito Sans;
                    font-size: 12px;
                    font-weight: lighter;
                }
                &:after {
                    bottom: 0;
                    top: 0px;
                    left: 24px;
                    right: 24px;
                    border-right: none;
                    border-top: 1px solid $midnight-20;
                }
            }
        }

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
            color: $dusk-lighter;
            margin: 0;
        }
        span {
            &.center {
                text-align: center;
            }
            &.light-gray {
                color: $dusk-lightest;
            }
        }

        > div {
            display: flex;

            position: relative;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            flex-direction: column;

            span {
                font-weight: bold;
            }

            &:after {
                content: "";
                top: 24px;
                bottom: 24px;
                right: 0;
                position: absolute;
                border-right: 1px solid $midnight-20;
            }

            &:last-child:after {
                display: none;
            }
        }
    }

    .subtitle {
        text-align: center;
        color: $dusk-lightest;
        font-size: 12px;
        font-family: Nunito Sans;

        & a {
            color: $dusk-lightest;
        }
    }

    .large-boxes-text-size {
        font-size: 25px;
        text-align: center;
    }
}
