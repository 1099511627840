.grid {
    display: grid;
    grid-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: auto;
    height: 100%;
}

.item {
    display: flex;
    justify-content: center;
    width: fit-content;
    padding: 0;

    &:hover {
        cursor: grab;
    }
}
