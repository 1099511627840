@import "../_palette";
@import "./colors.scss";

.Container {
    display: flex;
    align-items: center;
}

.Checkbox {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 3px 1px 1px 5px;

    &.disabled {
        opacity: 0.5;
    }
}

.inputCheck {
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    appearance: none;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid $midnight-40;
    border-radius: 4px;
    outline: none;
    position: relative;

    &:checked {
        &.error {
            background-color: $sunset;
            border-color: transparent;
        }
        &.success {
            background-color: $moss;
            border-color: transparent;
        }
        &.warning {
            background-color: $warning;
            border-color: transparent;
        }
        &.info {
            background-color: $info;
            border-color: transparent;
        }
        &.default {
            background-color: #0078ab;
            border-color: transparent;
        }

        &::after {
            box-sizing: border-box;
            content: "";
            position: absolute;
            width: 5px;
            height: 10px;
            border: 2px solid #fff;
            border-top: none;
            border-left: none;
            transform-origin: bottom right;
            transform: translateX(0) translateY(1px) rotate(45deg);
        }
    }
}
